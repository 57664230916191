export const ADA_API_URL = (username: string) => `https://api.${username}.beta.portal.it.a2z.com`;
export const ADA_LEGO_ENDPOINT = (username: string) =>
  `https://${username}.aka.corp.amazon.com:8443/collections/${USER_COLLECTION_MAP[username]}/documents/`;

export type GetLegoPageResponseObject = {
  content: string;
  reusables?: Record<string, string>;
};

export const USER_COLLECTION_MAP: Record<string, string> = {
  charaton: "charaton-lego-kb",
  jmpinol: "jmpinol-lego-kb",
};

export const API_URL: Record<string, string> = {
  beta: "https://api.beta.portal.it.a2z.com",
  gamma: "https://api.gamma.portal.it.a2z.com",
  prod: "https://api.portal.it.a2z.com",
};
export const LEGO_ENDPOINT: Record<string, string> = {
  beta: "https://beta.lego.a2z.com/collections/kb-portal-lego-beta/documents/",
  gamma: "https://na.lego.a2z.com/collections/kb-portal-lego-gamma/documents/",
  prod: "https://na.lego.a2z.com/collections/kb-portal-lego-prod/documents/",
};

export const PROD_WEBSITE_URL = "kb.it.a2z.com";
export const LEGO_ASSETS_ROOT_URL: Record<string, string> = {
  ada: "https://d3216uwaav9lg7.cloudfront.net",
  beta: "https://d3216uwaav9lg7.cloudfront.net",
  gamma: "https://d2670kf4pzqknp.cloudfront.net",
  prod: "https://d209e2j7j88hab.cloudfront.net",
};

export const LEGO_ASSET_FILE_NAME = "assets-KB";
export const AMAZON_UI_SRC =
  "https://images-na.ssl-images-amazon.com/images/I/81jr7UyxecL._RC%7C31+mDBETfKL.js,81Kgu6chTML.js,01ErspE30fL.js,31lpj1pPXEL.js,11fKXuXeWsL.js,512u7d882AL.js,41pF5pZDe+L.js,71zNQH36EkL.js,31yqywWRwtL.js,01qBV0ro0lL.js,21hCazhqKsL.js,21vW4A0l0BL.js,41LkAGaJlKL.js,213Dk1HPdeL.js,81eWaz0-yrL.js,1163r5YpAGL.js,01+ZphkXPOL.js,01oj-Oza3zL.js,41zn8Nwz2LL.js,01NvnKKl5BL.js,01L+bD75cvL.js_.js";
export const AMAZON_PAGE_UI_SRC = "https://images-na.ssl-images-amazon.com/images/I/31Y9lqhQ9OL.js";
export const JQUERY_SRC = "https://m.media-amazon.com/images/G/01/oneg/assets/3.2.6/js/jquery.min.js";

export const API_RENDERING_PATH = "/render/content/kb";
export const API_EDIT_URL_PATH = "/edit/kb";

export const MAIN_PAGE_TITLE = "ITSE Knowledge Base";

export const COOKIE_USERNAME = "username";
export const COOKIE_ADMIN = "admin";
export const HEADER_IS_ADMIN = "isAdmin";
export const HEADER_JSON_RESPONSE = "jsonResponse";

export const HEADER_X_FORWARDED_USER = "x-forwarded-user";
/* FeedbackWidget */
export const CFM_LIBRARY_BETA = "https://beta.feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js";
export const CFM_LIBRARY_PROD = "https://feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js";
export const FEEDBACK_LIBRARY: Record<string, string> = {
  beta: CFM_LIBRARY_BETA,
  gamma: CFM_LIBRARY_BETA,
  prod: CFM_LIBRARY_PROD,
  ada: CFM_LIBRARY_BETA,
};

export const WORKFLOW_MICROFRONTEND_URL: Record<string, string> = {
  prod: "https://workflow.it.a2z.com",
  gamma: "https://workflow.it.a2z.com",
  beta: "https://workflow.it.a2z.com",
  ada: "https://workflow.it.a2z.com",
};

export const QN_ENDPOINT_BETA = "bzb2snmm53.execute-api.us-west-2.amazonaws.com";
export const QN_ENDPOINT_PROD = "sdihol5j2j.execute-api.us-west-2.amazonaws.com";
export const QUITANIEVES_ENDPOINT: Record<string, string> = {
  beta: QN_ENDPOINT_BETA,
  gamma: QN_ENDPOINT_BETA,
  prod: QN_ENDPOINT_PROD,
  ada: QN_ENDPOINT_BETA,
};

export const SIM_TEMPLATE = "https://sim.amazon.com/issues/create?template=a6ef1de0-794c-4e43-afbb-f8d7bfbfad99";

export const GIT_FEEDBACK_RATING_SUBMIT = "GITFeedbackRatingSubmit";

export const GIT_FEEDBACK_COMMENT_SUBMIT = "GITFeedbackCommentSubmit";

export const METADATA_ARTICLES_FILTERED_PATH = "/metadata/kb/articles";

export const ARTICLE_METADATA_FILTERED_PATH = "/metadata/kb/meta-info";

export const USER_PHOTO_URL = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=";

export const LANDING_PAGE_SLUG = "/landing-page";
export const ANNOUNCEMENT_PAGE_SLUG = "/its-announcements";

export const USER = process.env.ADA_USER ?? "vkanteti";

/* `LOCALE_KEY` and `DEFAULT_LOCALE` are used by translations */
export const LOCALE_KEY = "kb-locale";
export const DEFAULT_LOCALE = "en";
