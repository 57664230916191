import {
  ADA_API_URL,
  ADA_LEGO_ENDPOINT,
  API_URL,
  FEEDBACK_LIBRARY,
  LEGO_ASSET_FILE_NAME,
  LEGO_ASSETS_ROOT_URL,
  METADATA_ARTICLES_FILTERED_PATH,
  PROD_WEBSITE_URL,
  QUITANIEVES_ENDPOINT,
  USER,
  WORKFLOW_MICROFRONTEND_URL,
} from "../config/constants";

export enum Stages {
  Prod = "prod",
  Beta = "beta",
  Gamma = "gamma",
  Ada = "ada",
}

enum AssetsType {
  CSS = "CSS",
  JS = "JS",
}

interface IGetMetadataRetrievalUrlParams {
  filter?: string;
  filteringList?: string[];
  maxArticles?: number;
  language?: string;
  revision?: number;
}

export default class UrlHelper {
  static getStage(): string {
    const hostname = window.location.hostname;
    return hostname.startsWith("beta")
      ? Stages.Beta
      : hostname.startsWith("gamma")
      ? Stages.Gamma
      : hostname.startsWith(PROD_WEBSITE_URL)
      ? Stages.Prod
      : Stages.Ada;
  }

  static getApiUrl(env: string): string {
    return env === Stages.Ada ? UrlHelper.getAdaApiUrl() : API_URL[env];
  }

  static getAdaApiUrl(): string {
    // TODO: add os module to fetch username; use the ada domain name when DNS implemented
    return ADA_API_URL(USER);
  }

  static getAdaLegoUrl(): string {
    // TODO: add os module to fetch username; use the ada domain name when DNS implemented
    return ADA_LEGO_ENDPOINT(USER);
  }

  private static getLegoAssetsUrl(type: AssetsType): string {
    const assetName =
      type === AssetsType.CSS
        ? `${LEGO_ASSET_FILE_NAME}.css`
        : type === AssetsType.JS
        ? `${LEGO_ASSET_FILE_NAME}.js`
        : "";
    return `${LEGO_ASSETS_ROOT_URL[this.getStage()]}/${assetName}`;
  }

  static getLegoStylesUrl(): string {
    return UrlHelper.getLegoAssetsUrl(AssetsType.CSS);
  }

  static getLegoJavascriptUrl(): string {
    return UrlHelper.getLegoAssetsUrl(AssetsType.JS);
  }

  static getFeedbackWidgetsUrl(): string {
    return FEEDBACK_LIBRARY[UrlHelper.getStage()];
  }

  static getQuitanievesEndpoint(): string {
    return QUITANIEVES_ENDPOINT[UrlHelper.getStage()];
  }

  static getWorkflowEndpoint(): string {
    return WORKFLOW_MICROFRONTEND_URL[UrlHelper.getStage()];
  }

  /**
   * Helper to build articles metadata search
   * @example
   * getMetadataRetrievalUrl({ filter: "category", filteringList: ["tools"], maxArticles: 3 })
   * // returns "https://api.portal.it.a2z.com/metadata/kb/articles?filter=category&filtering-list=tools&max-articles=3"
   */
  static getMetadataRetrievalUrl({
    filter,
    filteringList,
    maxArticles,
    language,
    revision,
  }: IGetMetadataRetrievalUrlParams): string {
    const apiUrl = UrlHelper.getApiUrl(UrlHelper.getStage());
    const url = new URL(METADATA_ARTICLES_FILTERED_PATH, apiUrl);
    const searchParams = new URLSearchParams(url.search);

    if (filter) {
      searchParams.append("filter", filter);
    }

    if (Array.isArray(filteringList) && filteringList.length > 0) {
      searchParams.append("filtering-list", filteringList.join(","));
    }

    if (maxArticles && maxArticles > 0) {
      searchParams.append("max-articles", maxArticles.toString());
    }

    if (language) {
      searchParams.append("language", language);
    }

    if (revision) {
      searchParams.append("revision", revision.toString());
    }

    url.search = searchParams.toString();
    return url.toString();
  }
}
